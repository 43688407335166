import { Container, Row, Col } from "react-bootstrap";

import Social from "../header/social/social";

import "./footer.scss";

export default function Footer({ social }) {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <p>
              Copyrights &copy; {new Date().getFullYear()}{" "}
              {process.env.REACT_APP_SITENAME} All Rights Reserved
            </p>
          </Col>
          <Col lg={6} xs={12}>
            <Social list={social} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
