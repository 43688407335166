import { Parallax } from "react-scroll-parallax";

import "./moving-object.scss";

export default function MovingElement({ children, speed = 10, className }) {
  return (
    <Parallax
      speed={speed}
      className={`moving-object${
        className && className.length > 0 ? " " + className : ""
      }`}
    >
      {children}
    </Parallax>
  );
}
