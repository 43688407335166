import { Container, Row, Col } from "react-bootstrap";

import { ReactComponent as Onions } from "../../../assets/images/onion.svg";
import MovingElement from "../../common/moving-element/moving-element";
import WideSeparator from "../../common/wide-separator/wide-separator";
import Subheading from "../../common/subheading/subheading";
import Title from "../../common/title/title";

import "./about.scss";

export default function About() {
  return (
    <div className="about-container">
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <div className="about-images">
              <div className="large-item">
                <img src="/assets/about5.jpg" alt="About Baytooty" />
              </div>
              <div className="item">
                <img src="/assets/about4.jpg" alt="About Baytooty" />
              </div>
              <div className="item">
                <img src="/assets/about6.jpg" alt="About Baytooty" />
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <div className="about-content">
              <Subheading>About Us</Subheading>
              <Title>
                Experience Lebanese
                <br />
                Gourmet
              </Title>
              <p>
                At Baytooty Gourmet Bakery, we redefine Lebanese dining with a
                modern twist. Our focus is on the unparalleled quality of our
                dishes, from the sourcing of our ingredients to the careful
                crafting of each recipe. We’re not just about food; we’re about
                creating a modern, high-quality dining experience that nourishes
                and delights.
              </p>
            </div>
          </Col>
        </Row>
        <MovingElement speed={-5} name="cucumber">
          <img src="/assets/cucumber.png" alt="Cucumber" />
        </MovingElement>
      </Container>
      <WideSeparator>
        <MovingElement speed={10} name="onions">
          <Onions />
        </MovingElement>
      </WideSeparator>
    </div>
  );
}
