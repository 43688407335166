import { Container, Row, Col } from "react-bootstrap";

import { ReactComponent as Sandwiches } from "../../../assets/images/sandwiches.svg";
import { ReactComponent as Mouajanat } from "../../../assets/images/mouajanat.svg";
import { ReactComponent as Croissant } from "../../../assets/images/croissant.svg";
import { ReactComponent as Platters } from "../../../assets/images/platters.svg";
import { ReactComponent as Gourmet } from "../../../assets/images/gourmet.svg";
import { ReactComponent as Sweets } from "../../../assets/images/sweets.svg";
import { ReactComponent as Pizza } from "../../../assets/images/pizza.svg";
import { ReactComponent as Kaak } from "../../../assets/images/kaak.svg";
import MovingElement from "../../common/moving-element/moving-element";
import Separator from "../../common/separator/separator";
import Button from "../../control/button/button";
import Title from "../../common/title/title";

import "./Menu.scss";

export default function Menu() {
  const list = [
    { id: 1, title: "Gourmet Appetizers", icon: <Gourmet /> },
    { id: 2, title: "Croissant", icon: <Croissant /> },
    { id: 3, title: "Kaak", icon: <Kaak /> },
    { id: 4, title: "Mouajanat", icon: <Mouajanat /> },
    { id: 5, title: "Pizza", icon: <Pizza /> },
    { id: 6, title: "Sandwiches", icon: <Sandwiches /> },
    { id: 7, title: "Signature Platters", icon: <Platters /> },
    { id: 8, title: "Sweets", icon: <Sweets /> },
  ];
  return (
    <div className="food-menu-container">
      <Container>
        <Row>
          <Col>
            <Title className="text-center">Our Food Menu</Title>
            <p className="text-center">
              Our modern Lebanese cuisine comes to life through our gourmet
              offerings
            </p>
            <div className="menu-list-container">
              <ul>
                {list.map(({ id, title, icon }) => (
                  <li key={id}>
                    <div className="icon-container">{icon}</div>
                    <h4>{title}</h4>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-center">
              <Button
                as="link"
                href="https://oddmenu.com/p/baytooty"
                target="_blank"
                rel="noreferrer"
              >
                View Menu
              </Button>
            </div>
            <Separator />
          </Col>
        </Row>
      </Container>
      <MovingElement speed={-5}>
        <img src="/assets/basel.svg" alt="" />
      </MovingElement>
    </div>
  );
}
