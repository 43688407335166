import { ReactComponent as Decoration } from "../../../assets/images/icon-decoration.svg";

import "./separator.scss";

export default function Separator() {
  return (
    <div className="separator-item">
      <span>
        <Decoration />
      </span>
    </div>
  );
}
