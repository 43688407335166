import { Container, Row, Col } from "react-bootstrap";

import { ReactComponent as Email } from "../../../assets/images/EnvelopeSimpleOpen.svg";
import { ReactComponent as MapMarker } from "../../../assets/images/MapPin.svg";
import { ReactComponent as Mobile } from "../../../assets/images/mobile.svg";
import { ReactComponent as Clock } from "../../../assets/images/Clock.svg";
import { ReactComponent as Truck } from "../../../assets/images/Truck.svg";
import { ReactComponent as Phone } from "../../../assets/images/Call.svg";
import MovingElement from "../../common/moving-element/moving-element";
import Subheading from "../../common/subheading/subheading";
import Social from "../../layout/header/social/social";
import Button from "../../control/button/button";
import Title from "../../common/title/title";
// import GoogleMap from "./map/map";

import "./contact.scss";

export default function Contact({ social, showDelivery }) {
  return (
    <div className="contact-container">
      <Container>
        <Row>
          <Col>
            <Subheading>Get in Touch</Subheading>
            <Title>
              Join us for a Meal,
              <br />
              or Just Order in.
            </Title>
          </Col>
        </Row>
        <Row className="d-none d-md-block">
          <Col>
            <div className="form-container">
              {/* <GoogleMap /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3629.9939452204176!2d54.436785799999996!3d24.520291899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67854242daab%3A0xa44ff15df746d756!2zQmF5dG9vdHkgR291cm1ldCBCYWtlcnkg2YXYrtio2LIg2KjZitiq2YjYqtmKINi62YjYsdmF2YrZhw!5e0!3m2!1sen!2str!4v1701884655843!5m2!1sen!2str"
                width="1264"
                height="475"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Baytooty"
              ></iframe>
              <Row>
                <Col lg={12}>
                  <Title>Baytooty Gourmet</Title>
                </Col>
                <Col lg={6}>
                  <ul className="contact-list">
                    <li>
                      <MapMarker />
                      <span>
                        Saadiyat Island, next to NYU
                        <br />
                        Nasayem 2 building, shop No 1
                      </span>
                    </li>
                    <li>
                      <Email />
                      <span>
                        <button
                          onClick={() => {
                            window.location = "mailto:info@baytooty.ae";
                          }}
                        >
                          info<span className="fa fa-at"></span>baytooty.ae
                        </button>
                      </span>
                    </li>
                    <li>
                      <Mobile />
                      <span>
                        <button
                          onClick={() => {
                            window.location = "tel:00971504042401";
                          }}
                        >
                          M. 050 4042401
                        </button>
                      </span>
                    </li>
                    <li>
                      <Phone />
                      <span>
                        <button
                          onClick={() => {
                            window.location = "tel:0097124466160";
                          }}
                        >
                          T. 02 4466160
                        </button>
                      </span>
                    </li>
                    <li>
                      <Clock />
                      <span>Mon - Sun / 7:00 AM - 10:00 PM</span>
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <Social
                    list={social}
                    className="footer-social"
                    addWhatsapp={true}
                  />
                  <Button alternative={true} onClick={() => showDelivery()}>
                    <Truck />
                    Order Delivery
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-md-none">
        <Container className="mobile-map">
          <Row>
            <Col>
              {/* <GoogleMap /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3629.9939452204176!2d54.436785799999996!3d24.520291899999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67854242daab%3A0xa44ff15df746d756!2zQmF5dG9vdHkgR291cm1ldCBCYWtlcnkg2YXYrtio2LIg2KjZitiq2YjYqtmKINi62YjYsdmF2YrZhw!5e0!3m2!1sen!2str!4v1701884655843!5m2!1sen!2str"
                width="380"
                height="200"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Baytooty"
              ></iframe>
            </Col>
          </Row>
        </Container>
        <Container className="mobile-contact">
          <Row>
            <Col>
              <Title>Baytooty Gourmet</Title>
              <ul className="contact-list">
                <li>
                  <MapMarker />
                  <span>
                    Saadiyat Island, next to NYU
                    <br />
                    Nasayem 2 building, shop No 1
                  </span>
                </li>
                <li>
                  <Email />
                  <span>
                    <button
                      onClick={() => {
                        window.location = "mailto:info@baytooty.ae";
                      }}
                    >
                      info<span className="fa fa-at"></span>baytooty.ae
                    </button>
                  </span>
                </li>
                <li>
                  <Mobile />
                  <span>
                    <button
                      onClick={() => {
                        window.location = "tel:00971504042401";
                      }}
                    >
                      M. 050 4042401
                    </button>
                  </span>
                </li>
                <li>
                  <Phone />
                  <span>
                    <button
                      onClick={() => {
                        window.location = "tel:0097124466160";
                      }}
                    >
                      T. 02 4466160
                    </button>
                  </span>
                </li>
                <li>
                  <Clock />
                  <span>Mon - Sun / 7:00 AM - 10:00 PM</span>
                </li>
              </ul>
              <Social
                list={social}
                className="footer-social"
                addWhatsapp={true}
              />
              <Button alternative={true}>
                <Truck />
                Order Delivery
              </Button>
            </Col>
          </Row>
          <MovingElement speed={10}>
            <img src="/assets/lettuce2.png" alt="Lettuce" />
          </MovingElement>
        </Container>
      </div>
      <MovingElement speed={10}>
        <img src="/assets/lettuce2.png" alt="Lettuce" />
      </MovingElement>
    </div>
  );
}
