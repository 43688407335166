import "./button.scss";

export default function Button({
  children,
  as = "button",
  alternative,
  ...props
}) {
  return (
    <>
      {as === "button" && (
        <button
          className={`button${alternative ? " alt-button" : ""}`}
          {...props}
        >
          {children}
        </button>
      )}
      {as === "link" && (
        <a className={`button${alternative ? " alt-button" : ""}`} {...props}>
          {children}
        </a>
      )}
    </>
  );
}
