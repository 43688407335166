import { Modal } from "react-bootstrap";

import "./delivery.scss";

export default function DeliveryModal({ show, handleClose, list }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>Delivery Apps</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delivery-apps-container">
          {list.map(({ id, title, url, logo }) => (
            <div key={id} className="delivery-app-item">
              <a href={url} target="_blank" rel="noreferrer">
                <img src={`/assets/${logo}`} alt={title} />
                <span className="delivery-button">Visit App</span>
              </a>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
