import { Container, Navbar, Nav, Offcanvas } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Button, scrollSpy } from "react-scroll";

import { ReactComponent as Logo } from "../../../assets/logo.svg";
import Social from "./social/social";

import "./header.scss";

export default function Header({ nav, social }) {
  const [show, setShow] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsSticky(window.scrollY > 10);
    });
    scrollSpy.update();
  }, []);

  return (
    <header>
      <Navbar expand="lg" className={isSticky ? "sticky-top" : ""}>
        <Container>
          <div className="d-flex header-container">
            <Navbar.Toggle aria-controls="main-navigation" onClick={handleShow}>
              <span className="fa fa-bars"></span>
            </Navbar.Toggle>
            <Navbar.Brand>
              <Logo />
            </Navbar.Brand>
            <Navbar.Offcanvas
              id="main-navigation"
              className="nav-offcanvas"
              responsive="lg"
              show={show}
              onHide={handleHide}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvas-title">
                  <Logo />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav>
                  {nav.map(({ id, title, target }) => (
                    <Button
                      key={id}
                      className="nav-link"
                      activeClass="active"
                      offset={-150}
                      to={target}
                      spy={true}
                      smooth={true}
                      onClick={handleHide}
                    >
                      {title}
                    </Button>
                  ))}
                </Nav>
                <Social list={social} forMobile={true} />
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </div>
          <div className="header-border"></div>
          <Social list={social} forMobile={false} className="bg-fix" />
        </Container>
      </Navbar>
    </header>
  );
}
