import { ParallaxProvider } from "react-scroll-parallax";
import Lightbox from "yet-another-react-lightbox";
import { ToastContainer } from "react-toastify";
import { useState, useEffect } from "react";
import { Element } from "react-scroll";

import DeliveryModal from "./components/modals/delivery/delivery";
import Gallery from "./components/layout/gallery/gallery";
import Contact from "./components/layout/contact/contact";
import Header from "./components/layout/header/header";
import Banner from "./components/layout/banner/banner";
import Footer from "./components/layout/footer/footer";
import About from "./components/layout/About/About";
import Menu from "./components/layout/Menu/Menu";

import data from "./assets/data.json";

function App() {
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const { menu, social, delivery, gallery, banners } = data;

  const handleShowDeliveryModal = () => setShowDeliveryModal(true);
  const handleHideDeliveryModal = () => setShowDeliveryModal(false);

  const handleCloseLightbox = () => setOpenLightbox(false);
  const handleOpenLightbox = (images) => {
    setLightboxImages(
      images.map(({ image, title }) => ({ alt: title, src: image, title }))
    );
    setOpenLightbox(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsSticky(window.scrollY > 10);
    });
  }, []);

  return (
    <div className={`App${isSticky ? " app-sticky" : ""}`}>
      <ToastContainer position="top-center" autoClose={5000} theme="colored" />
      <Header nav={menu} social={social} />
      <ParallaxProvider>
        <div id="home">
          <Element name="home">
            <Banner showDelivery={handleShowDeliveryModal} list={banners} />
          </Element>
        </div>
        <div id="about">
          <Element name="about">
            <About />
          </Element>
        </div>
        <div id="menu">
          <Element name="menu">
            <Menu />
          </Element>
        </div>
        <div id="gallery">
          <Element name="gallery">
            <Gallery openLightbox={handleOpenLightbox} list={gallery} />
          </Element>
        </div>
        <div id="contact">
          <Element name="contact">
            <Contact social={social} showDelivery={handleShowDeliveryModal} />
          </Element>
        </div>
      </ParallaxProvider>
      <Footer social={social} />
      <DeliveryModal
        show={showDeliveryModal}
        handleClose={handleHideDeliveryModal}
        list={delivery}
      />
      <Lightbox
        open={openLightbox}
        close={() => handleCloseLightbox()}
        slides={lightboxImages}
      />
    </div>
  );
}

export default App;
