import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";

import { ReactComponent as PlateDecoration } from "../../../assets/images/plate-decoration.svg";
import MovingElement from "../../common/moving-element/moving-element";
import Subheading from "../../common/subheading/subheading";
import Separator from "../../common/separator/separator";
import Button from "../../control/button/button";
import Title from "../../common/title/title";

import "swiper/css";
import "swiper/css/effect-fade";
import "./banner.scss";

export default function Banner({ showDelivery, list }) {
  return (
    <div className="main-banner">
      <Container>
        <Row className="justify-content-center main-banner-row">
          <Col lg={5} md={6} xs={8}>
            <div className="caption-container">
              <Subheading>Lebanese with a twist</Subheading>
              <Title addDecoration={true} mainTitle={true}>
                Enjoy Light
                <br />
                Tasty Food
              </Title>
              <p>
                Step into Baytooty Gourmet Bakery, where every dish is a
                testament to exceptional quality. Experience the modern essence
                of Lebanese cuisine, crafted to perfection in the heart of Abu
                Dhabi's <strong>Saadiyat Island</strong>.
              </p>
              <Button onClick={() => showDelivery()}>Place an Order</Button>
            </div>
          </Col>
          <Col lg={7} md={6} xs={4}>
            <div className="image-container">
              <PlateDecoration />
              {/* <img src="/assets/dolma.png" alt="dolma" /> */}
              <Swiper
                modules={[EffectFade, Autoplay]}
                effect="fade"
                slidesPerView={1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                fadeEffect={{ crossFade: true }}
              >
                {list.map(({ id, image, title }) => (
                  <SwiperSlide key={id}>
                    <img src={image} alt={title} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Col>
        </Row>
        <div className="scroll-down">
          <span>Scroll Down</span>
        </div>
        <Separator />
        <MovingElement speed={15}>
          <img src="/assets/olives.png" alt="Olives" />
        </MovingElement>
      </Container>
    </div>
  );
}
