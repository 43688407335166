import { ReactComponent as Instagram } from "../../../../assets/instagram.svg";
import { ReactComponent as Facebook } from "../../../../assets/facebook.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/whatsapp.svg";
import { ReactComponent as TikTok } from "../../../../assets/tiktok.svg";

import "./social.scss";

export default function Social({
  list,
  forMobile = false,
  className,
  addWhatsapp = false,
}) {
  return (
    <div
      className={`social${forMobile ? "-mobile" : ""}${
        className && className.length ? " " + className : ""
      }`}
    >
      <ul>
        {list.map(({ id, url, title, icon }) => (
          <li key={id}>
            <a href={url} target="_blank" rel="noreferrer" title={title}>
              {icon === "instagram" && <Instagram />}
              {icon === "facebook" && <Facebook />}
              {icon === "tiktok" && <TikTok />}
              {addWhatsapp && icon === "whatsapp" && <Whatsapp />}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
