import { ReactComponent as Decoration } from "../../../assets/images/icon-decoration.svg";

import "./title.scss";

export default function Title({
  children,
  addDecoration = false,
  mainTitle = false,
  className,
}) {
  if (mainTitle)
    return (
      <h1
        className={`title${addDecoration ? " with-decoration" : ""}${
          className && className.length ? " " + className : ""
        }`}
      >
        {addDecoration ? <span>{children}</span> : children}
        {addDecoration ? <Decoration /> : null}
      </h1>
    );
  return (
    <h2
      className={`title${addDecoration ? " with-decoration" : ""}${
        className && className.length ? " " + className : ""
      }`}
    >
      {addDecoration ? <span>{children}</span> : children}
      {addDecoration ? <Decoration /> : null}
    </h2>
  );
}
