import { Container, Row, Col } from "react-bootstrap";

import MovingElement from "../../common/moving-element/moving-element";
import Subheading from "../../common/subheading/subheading";
import Separator from "../../common/separator/separator";
import Button from "../../control/button/button";
import Title from "../../common/title/title";

import "./gallery.scss";

export default function Gallery({ openLightbox, list }) {
  const openGallery = (images) => openLightbox(images);
  const openVenueGallery = () => openGallery(list.venue);
  const openFoodGallery = () => openGallery(list.food);
  return (
    <div className="gallery-container">
      <Container>
        <Row>
          <Col lg={6} xs={12}>
            <div className="gallery-images">
              <div className="item">
                <img src="/assets/gallery4.jpg" alt="Food" />
              </div>
              <div className="item">
                <img src="/assets/gallery3.jpg" alt="Restaurant" />
              </div>
            </div>
          </Col>
          <Col lg={6} xs={12}>
            <Subheading>Gallery</Subheading>
            <Title>A Feast for The Eyes</Title>
            <p>
              Take a visual tour of our delightful dishes and cozy atmosphere.
              It's a glimpse into the heart of Baytooty Gourmet Bakery.
            </p>
            <div className="btns-container">
              <Button onClick={() => openFoodGallery()}>Browse Food</Button>
              <Button onClick={() => openVenueGallery()}>Browse Venue</Button>
            </div>
          </Col>
        </Row>
      </Container>
      <MovingElement className="element-1" speed={-5}>
        <img src="/assets/tomato.svg" alt="Tomato" />
      </MovingElement>
      <MovingElement className="element-2" speed={20}>
        <img src="/assets/coriander2.png" alt="Coriander" />
      </MovingElement>
      <Separator />
    </div>
  );
}
